'use strict';

document.documentElement.className = document.documentElement.className.replace( 'no-js', 'js' );

const navItems = document.querySelectorAll( 'ul.site-navigation-menu li' );
const currentUrl = window.location.pathname;

navItems.forEach( function ( item ) {
    const itemLinkLocation = item.querySelector( 'a' ).getAttribute( 'href' );

    if ( itemLinkLocation.replace(/^\//, '') == currentUrl.split('/')[1] ) {
        item.querySelector( 'a' ).setAttribute( 'aria-current', 'page' );
    }
} );

// document.querySelector( 'body' ).classList.add( window.location.pathname.split( '/' )[0] );

/**
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
( function() {
    var container, button, menu, links, i, len;

    container = document.querySelector( '.site-navigation' );
    if ( typeof container === 'undefined' ) {
        return;
    }

    button = document.querySelector( '.site-navigation-menu-toggle' );
    if ( typeof button === 'undefined' ) {
        return;
    }

    menu = container.getElementsByTagName( 'ul' )[0];

    // Hide menu toggle button if menu is empty and return early.
    if ( typeof menu === 'undefined' ) {
        button.style.display = 'none';
        return;
    }

    menu.setAttribute( 'aria-expanded', 'false' );
    if ( menu.className.indexOf( 'site-navigation-menu' ) === -1 ) {
        menu.className += ' site-navigation-menu';
    }

    function toggleMenu() {
        if ( container.classList.contains( 'toggled' ) ) {
            container.classList.remove( 'toggled' );
            document.body.classList.remove( 'menu-expanded' );
            button.setAttribute( 'aria-expanded', 'false' );
            menu.setAttribute( 'aria-expanded', 'false' );
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
            headroom.init();
        } else {
            container.classList.add( 'toggled' );
            document.body.classList.add( 'menu-expanded' );
            button.setAttribute( 'aria-expanded', 'true' );
            menu.setAttribute( 'aria-expanded', 'true' );

            if ( headerElement.classList.contains( 'headroom--top' ) ) {
                headroom.destroy();
                headerElement.classList.add( 'headroom--top' );
                document.body.style.overflow = 'hidden';
                document.documentElement.style.overflow = 'hidden';
            } else {
                headroom.destroy();
            }
        }
    }

    button.addEventListener( 'click', toggleMenu, false );
    document.querySelector('.site-navigation-menu')
        .addEventListener( 'click', function() {
            if (event.currentTarget !== event.target) {
                return;
            }
            toggleMenu();
        }, false );

    // Get all the link elements within the menu.
    links = menu.getElementsByTagName( 'a' );

    // Each time a menu link is focused or blurred, toggle focus.
    for ( i = 0, len = links.length;i < len;i++ ) {
        links[i].addEventListener( 'focus', toggleFocus, true );
        links[i].addEventListener( 'blur', toggleFocus, true );
    }

    /**
     * Sets or removes .focus class on an element.
     */
    function toggleFocus() {
        var self = this;

        // Move up through the ancestors of the current link until we hit .nav-menu.
        while ( self.className.indexOf( 'site-navigation-menu' ) === -1 ) {

            // On li elements toggle the class .focus.
            if ( self.tagName.toLowerCase() === 'li' ) {
                if ( self.className.indexOf( 'focus' ) !== -1 ) {
                    self.className = self.className.replace( ' focus', '' );
                } else {
                    self.className += ' focus';
                }
            }

            self = self.parentElement;
        }
    }
}());